<template>
  <SettingsCard
    :title="$t('settings.features.widgets.title')"
    :subtitle="$t('settings.features.widgets.subtitle')"
    icon="mdi-widgets-outline"
    :is-default-open="isDefaultOpen"
  >
    <div class="pa-4">
      <v-row>
        <v-col
          cols="12"
          sm="6"
          class="px-0 px-md-2"
        >
          <div class="d-flex flex-column gap-1">
            <span class="font-weight-bold">
              {{ $t('settings.features.widgets.body-texts.0') }}<br>
            </span>
            <span class="grey--text">
              {{ $t('settings.features.widgets.body-texts.1') }}
            </span>
            <ol class="d-flex flex-column gap-1">
              <li class="grey--text">
                <strong>{{ $t('settings.features.widgets.body-texts.2') }}:</strong><br>
                {{ $t('settings.features.widgets.body-texts.3') }}
              </li>
              <li class="grey--text">
                <strong>{{ $t('settings.features.widgets.body-texts.4') }}:</strong><br>
                {{ $t('settings.features.widgets.body-texts.5') }}
              </li>
            </ol>
          </div>
        </v-col>
        <v-col
          cols="12"
          sm="6"
          class="px-0 px-md-2"
        >
          <v-radio-group
            v-if="widgetType"
            :value="widgetType"
            :label="$t('settings.features.widgets.labels.mode')"
            class="widgets-settings-radio-group"
            :loading="loading"
            :disabled="loading"
            @change="saveSettings"
          >
            <v-radio
              :label="$t('settings.features.widgets.labels.mode-iframe')"
              value="IFRAME"
            />
            <v-radio
              :label="$t('settings.features.widgets.labels.mode-widget')"
              value="WIDGET"
            />
          </v-radio-group>
          <ProgressCircular v-else />
        </v-col>
      </v-row>

      <v-alert
        v-if="widgetType === 'IFRAME'"
        icon="mdi-alert"
        prominent
        text
        type="warning"
        class="ma-0 mt-4"
      >
        <p class="ma-0 font-weight-bold">
          {{ $t('labels.hint') }}:
        </p>
        <p>
          {{ $t('settings.features.widgets.info-text') }}
        </p>
      </v-alert>
    </div>

    <v-dialog
      v-model="showUpdateWidgetTypeDialog"
      width="700"
    >
      <v-card>
        <v-card-title
          class="pa-0"
        >
          <v-toolbar
            color="primary"
            dark
            flat
          >
            <v-toolbar-title>
              {{ $t('settings.features.widgets.dialog.title') }}
            </v-toolbar-title>
            <v-spacer />
            <v-toolbar-items>
              <v-btn
                icon
                dark
                @click="showUpdateWidgetTypeDialog = false"
              >
                <v-icon large>
                  mdi-close
                </v-icon>
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>
        </v-card-title>

        <v-stepper v-model="step">
          <v-stepper-items>
            <v-stepper-content step="init">
              <v-card-text class="mt-4 text-body-1">
                <div>
                  <p>{{ $t('settings.features.widgets.info-text') }}</p>
                  {{ $t('settings.features.widgets.dialog.confirm') }}:
                  <v-checkbox
                    v-model="confirmWidget"
                    :label="$t('settings.features.widgets.dialog.confirm-checkbox')"
                  />
                </div>
              </v-card-text>

              <v-card-actions>
                <v-spacer />
                <v-btn
                  :disabled="!confirmWidget"
                  color="primary"
                  @click="updateWidgetTypeToWidget"
                >
                  {{ $t('settings.features.widgets.dialog.activate') }}
                </v-btn>
              </v-card-actions>
            </v-stepper-content>

            <v-stepper-content step="loading">
              <v-card-text>
                <ProgressCircular />
              </v-card-text>
            </v-stepper-content>

            <v-stepper-content step="success">
              <v-card-text class="d-flex flex-column align-center justify-center gap-1">
                <v-icon
                  color="success"
                  class="mr-4 white"
                  size="150"
                >
                  mdi-check-circle
                </v-icon>
                <p>{{ $t('settings.features.widgets.dialog.success') }}</p>
              </v-card-text>

              <v-card-actions>
                <v-spacer />
                <v-btn
                  color="primary"
                  outlined
                  @click="showUpdateWidgetTypeDialog = false"
                >
                  {{ $t('labels.close') }}
                </v-btn>
              </v-card-actions>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-card>
    </v-dialog>
  </SettingsCard>
</template>

<script>
import SettingsCard from '@/components/SettingsCard.vue'
import UPDATE_WIDGET_TYPE from './queries/UpdateWidgetType.gql'
import GET_CMS_ORGANIZATION from './queries/GetCmsOrganization.gql'
import bus, { eventNames } from '@/lib/eventBus'
import ProgressCircular from '@/components/ProgressCircular.vue'

export default {
  components: { SettingsCard, ProgressCircular },
  props: {
    isDefaultOpen: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      widgetType: null,
      loading: false,
      showUpdateWidgetTypeDialog: false,
      confirmWidget: false,
      step: 'init'
    }
  },
  apollo: {
    cmsOrganization: {
      query: GET_CMS_ORGANIZATION,
      variables () {
        return {
          companyId: this.$auth.user.companyId
        }
      },
      update (data) {
        this.widgetType = data.cmsOrganization.widgetType
        return data.cmsOrganization
      }
    }
  },
  watch: {
    showUpdateWidgetTypeDialog (val) {
      // Reset the widgetType when the dialog is closed
      this.widgetType = val ? null : this.cmsOrganization.widgetType
    }
  },
  methods: {
    async saveSettings (widgetType) {
      if (widgetType === 'WIDGET') {
        this.step = 'init'
        this.showUpdateWidgetTypeDialog = true
        this.widgetType = null
      } else {
        this.updateWidgetType(widgetType)
      }
    },
    async updateWidgetTypeToWidget () {
      this.step = 'loading'
      await new Promise(resolve => setTimeout(resolve, 2000))
      await this.updateWidgetType('WIDGET')
      this.step = 'success'
    },
    async updateWidgetType (widgetType) {
      this.loading = true
      try {
        const { data } = await this.$apollo.mutate({
          mutation: UPDATE_WIDGET_TYPE,
          variables: {
            input: {
              companyId: this.$auth.user.companyId,
              widgetType
            }
          },
          refetchQueries: [{ query: GET_CMS_ORGANIZATION, variables: { companyId: this.$auth.user.companyId } }]
        })
        this.widgetType = data.cmsOrganization.widgetType
        bus.$emit(eventNames.SHOW_SNACKBAR, { color: 'success', text: this.$t('alerts.settings.success') })
      } catch (e) {
        bus.$emit(eventNames.SHOW_SNACKBAR, { color: 'error', text: this.$t('alerts.settings.features.error') })
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style>
.widgets-settings-radio-group legend {
  white-space: pre-line;
}
</style>
