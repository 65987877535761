<template>
  <v-dialog
    :value="showDialog"
    persistent
    max-width="1200"
    :fullscreen="$vuetify.breakpoint.smAndDown"
    @input="$emit('update:showDialog', $event)"
  >
    <v-card>
      <v-card-title
        class="pa-0"
      >
        <v-toolbar
          color="primary"
          dark
          flat
        >
          <v-toolbar-title v-text="$t('product-packages.main-title')" />
          <v-spacer />
          <v-toolbar-items>
            <v-btn
              icon
              dark
              @click="$emit('update:showDialog', false)"
            >
              <v-icon large>
                mdi-close
              </v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
      </v-card-title>
      <v-stepper
        v-model="step"
        class="change-package-dialog"
      >
        <v-stepper-items>
          <v-stepper-content
            step="product-package"
            class="pa-0"
          >
            <v-card-text class="d-flex justify-space-between text-align-center pt-0 pt-md-6 mb-6">
              <ProductPackages
                :show-currency-selector="false"
                :company="company"
                @change-package="handlePackageChange"
              />
            </v-card-text>
          </v-stepper-content>

          <v-stepper-content
            step="payment"
            class="pa-0"
          >
            <PaymentUpgrade
              v-if="step === 'payment'"
              :product-type="productType"
              :is-yearly="isYearly"
              :currency="currency"
              @back="step = 'product-package'"
            />
          </v-stepper-content>

          <v-stepper-content
            step="downgrade"
            class="pa-0"
          >
            <Downgrade
              v-if="step === 'downgrade'"
              :product-type="productType"
              :is-yearly="isYearly"
              :currency="currency"
              @back="step = 'product-package'"
              @success="handleDowngradeSuccess"
            />
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </v-card>
  </v-dialog>
</template>

<script>
import PaymentUpgrade from '@/modules/payment/PaymentUpgrade.vue'
import ProductPackages from '@/modules/productPackages/index.vue'
import Downgrade from './Downgrade.vue'
import { billwerkApi } from '@/modules/billwerk/lib'

import SELF_SERVICE_PORTAL_URL from '@/modules/settings/accountSettings/queries/SelfServicePortalUrl.gql'

export default {
  components: {
    ProductPackages, PaymentUpgrade, Downgrade
  },
  props: {
    company: {
      type: Object,
      default: () => {}
    },
    showDialog: {
      type: Boolean,
      default: false
    },
    defaultProductType: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      step: 'product-package',
      isYearly: true,
      currency: '',
      productType: ''
    }
  },
  watch: {
    showDialog (val) {
      if (val) {
        this.step = 'product-package'
      }
    }
  },
  mounted () {
    if (this.defaultProductType) {
      this.productType = this.defaultProductType
      this.step = 'payment'
    }
  },
  methods: {
    handleDowngradeSuccess () {
      this.$emit('update:showDialog', false)
      this.$emit('downgrade')
    },
    handlePackageChange ({ productType, isYearly, currency }) {
      this.productType = productType
      this.isYearly = isYearly
      this.currency = currency
      this.step = {
        BEGINNER: 'downgrade',
        ADVANCED: 'payment'
      }[productType]
    }
  },
  apollo: {
    token: {
      query: SELF_SERVICE_PORTAL_URL,
      async update (data) {
        const token = data.billingSelfServicePortalDetails?.Token
        if (token) {
          const { Contract } = await billwerkApi.getContractDetails(token)
          this.currency = Contract.Currency
        }
      }
    }
  }
}
</script>

<style scoped>
.change-package-dialog {
  box-shadow: none !important;
}
</style>
