<template>
  <Feature
    v-slot="{feature}"
    :feature-slug="featureNames.COMPANY"
  >
    <div class="mid-gray">
      <Feature :feature-slug="featureNames.ONBOARDING_PREVIEW">
        <div>
          <div v-if="isOnboarding && data.previewLink">
            <v-alert
              class="mx-n4"
              type="info"
              text
            >
              {{ $t('onboarding.publication.previewMessage') }}
              <a
                :href="data.previewLink"
                target="_blank"
                rel="noopener noreferrer"
              >{{ $t('onboarding.publication.previewLinkText') }}</a>
            </v-alert>
          </div>
        </div>
      </Feature>
      <div>
        <h2 class="pr-4 ml-n2">
          {{ $t('onboarding.publication.contactTitle') }}
        </h2>
      </div>

      <div class="pb-4 mb-2">
        <h4 class="pr-4 ml-n2">
          {{ $t('onboarding.publication.contactSubtitle') }}
        </h4>
      </div>
      <v-row
        class="d-flex justify-space-between"
        :class="{'flex-column': $vuetify.breakpoint.smAndDown}"
      >
        <v-col
          cols="12"
          md="2"
          class="pr-0 pa-0 pr-md-2"
        >
          <v-text-field
            ref="ambassador.salutation"
            v-model.lazy="data.ambassador.salutation"
            outlined
            :label="$t('onboarding.publication.salutation')"
            :placeholder="$t('onboarding.publication.salutationPlaceholder')"
            validate-on-blur
          />
        </v-col>
        <v-col
          cols="12"
          md="5"
          class="pr-0 pa-0 pr-md-2"
        >
          <v-text-field
            id="ambassador_firstname"
            ref="ambassador.firstname"
            v-model.lazy="data.ambassador.firstname"
            outlined
            :label="$t('onboarding.publication.firstname')"
            :placeholder="$t('onboarding.publication.firstnamePlaceholder')"
            validate-on-blur
            :rules="[rules.optionalForOnboarding(isOnboarding)]"
          />
        </v-col>
        <v-col
          cols="12"
          md="5"
          class="pa-0"
        >
          <v-text-field
            ref="ambassador.lastname"
            v-model.lazy="data.ambassador.lastname"
            outlined
            :label="$t('onboarding.publication.lastname')"
            :placeholder="$t('onboarding.publication.lastnamePlaceholder')"
            validate-on-blur
            :rules="[rules.optionalForOnboarding(isOnboarding)]"
          />
        </v-col>
      </v-row>
      <v-row class="d-flex justify-space-between">
        <v-text-field
          ref="ambassador.role"
          v-model.lazy="data.ambassador.role"
          outlined
          :label="$t('onboarding.publication.role')"
          :placeholder="$t('onboarding.publication.rolePlaceholder')"
          validate-on-blur
          :rules="[rules.optionalForOnboarding(isOnboarding)]"
        />
      </v-row>
      <div class="pb-4 mb-2">
        <h4 class="pr-4 ml-n2">
          {{ $t('onboarding.publication.contactDetails') }}
        </h4>
      </div>
      <v-row class="d-flex justify-space-between">
        <v-col
          cols="12"
          sm="6"
          class="pr-0 pa-0 pr-md-2"
        >
          <PhoneField
            id="ambassador_phone"
            v-model="data.ambassador.phone"
            :rules="[rules.optionalForOnboarding(isOnboarding)]"
            validate-on-blur
          />
        </v-col>
        <v-col
          cols="12"
          sm="6"
          class="pa-0 mt-7 mt-md-0"
        >
          <PhoneField
            id="ambassador_mobile"
            v-model="data.ambassador.mobile"
            :label="$t('onboarding.publication.mobile')"
            :rules="[rules.optionalForOnboarding(isOnboarding)]"
            validate-on-blur
          />
        </v-col>
      </v-row>

      <v-row class="d-flex justify-space-between py-4 mt-7 mt-md-5">
        <v-text-field
          ref="ambassador.email"
          v-model.lazy="data.ambassador.email"
          type="email"
          outlined
          :label="$t('onboarding.publication.email')"
          :placeholder="$t('onboarding.publication.emailPlaceholder')"
          :rules="[rules.email, rules.optionalForOnboarding(isOnboarding)]"
          validate-on-blur
          :disabled="!isOnboarding"
        />
      </v-row>
      <div class="pb-4 mb-2">
        <h2 class="pr-4 ml-n2">
          {{ $t('onboarding.publication.sloganTitle') }}
        </h2>
      </div>
      <v-row>
        <v-text-field
          ref="slogan"
          v-model.lazy="data.slogan"
          outlined
          :label="$t('onboarding.publication.slogan')"
          :placeholder="$t('onboarding.publication.sloganPlaceholder')"
          validate-on-blur
          :rules="[rules.optionalForOnboarding(isOnboarding)]"
        />
      </v-row>

      <div v-if="feature.config.isColorEditable">
        <div class="pb-4">
          <h2 class="pr-4 d-inline">
            {{ $t('onboarding.publication.colorsTitle') }}
          </h2>
        </div>
        <div class="pb-4">
          <h4 class="pb-4 d-inline">
            {{ $t('onboarding.publication.colorsSubtitle') }}
          </h4>
        </div>
        <v-row>
          <v-col
            cols="12"
            md="6"
            class="pr-2 pa-0 align-center d-flex flex-column"
          >
            <div class="px-4">
              <h4>
                {{ $t('onboarding.publication.primaryColor') }}
              </h4>
              <ColorPicker v-model.lazy="data.theme.colors.primary" />
            </div>
          </v-col>
          <v-col
            cols="12"
            md="6"
            class="pr-2 pa-0 align-center d-flex flex-column"
          >
            <div class="px-4">
              <h4>
                {{ $t('onboarding.publication.secondaryColor') }}
              </h4>
              <ColorPicker v-model.lazy="data.theme.colors.secondary" />
            </div>
          </v-col>
        </v-row>
      </div>
      <div class="pt-4 pb-4">
        <h2 class="pr-4 ml-n2">
          {{ $t('onboarding.publication.imagesTitle') }}
        </h2>
      </div>
      <v-row>
        <v-col
          v-for="image of imageArray"
          :key="image.name"
          cols="12"
          md="6"
        >
          <ImageContainer
            v-bind="image"
            :save-image="saveImage"
            :delete-image="deleteImage"
          />
        </v-col>
      </v-row>

      <Feature :feature-slug="featureNames.CONTRACT">
        <div>
          <div
            v-if="isOnboarding"
            class="pt-4"
          >
            <h2 class="pr-4 d-inline">
              {{ $t('onboarding.publication.logoApprovalTitle') }}
            </h2>
          </div>
          <v-row v-if="isOnboarding">
            <v-col>
              <h4 class="pb-4">
                {{ $t('onboarding.publication.logoApprovalSubtitle') }}
              </h4>
              <v-radio-group
                v-model="data.isReferenceCustomer"
                row
                :rules="[rules.optionalBooleanForOnboarding(isOnboarding)]"
              >
                <v-radio
                  :label="$t('onboarding.publication.logoApprovalYes')"
                  :value="true"
                />
                <v-radio
                  :label="$t('onboarding.publication.logoApprovalNo')"
                  :value="false"
                />
              </v-radio-group>
            </v-col>
          </v-row>
        </div>
      </Feature>
    </div>
  </Feature>
</template>

<script>
import { email, optionalForOnboarding, optionalBooleanForOnboarding } from '@/lib/validation'
import featureMixin from '@/mixins/feature'
import ColorPicker from '../ColorPicker.vue'
import PhoneField from '@/components/PhoneField.vue'
import ImageContainer from '@/components/ImageContainer.vue'
import heroImage from '@/lib/customHeroMapper'

export default {
  components: { ColorPicker, PhoneField, ImageContainer },
  mixins: [featureMixin],
  props: {
    data: {
      type: Object,
      default: () => ({})
    },
    isOnboarding: {
      type: Boolean,
      default: false
    },
    saveAmbassadorImage: {
      type: Function,
      required: true
    },
    saveCompanyImage: {
      type: Function,
      required: true
    },
    deleteCompanyImage: {
      type: Function,
      default: () => {}
    },
    variant: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      images: {},
      ambassadorImages: {},
      defaultSignatures: {
        text: 'default text',
        html: '<h3>default html</h3>'
      },
      previewHTMLSignature: false,
      previewTextSignature: false
    }
  },
  computed: {
    hasLpValuationHero () {
      return this.getFeature(this.featureNames.VALUATION).config.hasLpValuationHero
    },
    valuationHeroImage () {
      return this.data?.images?.lpValuationHero?.url || heroImage({ variant: this.variant, lpSlug: 'immobilienbewertung', useDefault: !this.hasLpValuationHero })
    },
    imageArray () {
      return [
        {
          name: 'logo',
          title: this.$t('onboarding.publication.logo'),
          src: this.images.logo || this.data.images.logo.url,
          disabled: !this.getFeature(this.featureNames.COMPANY)?.config?.isLogoEditable,
          minHeight: 100,
          minWidth: 1200
        },
        {
          name: 'team',
          title: this.$t('onboarding.publication.teamImage'),
          src: this.images.team || this.data.images.team.url,
          minHeight: 800,
          minWidth: 1200,
          ratio: 1.5
        },
        {
          name: 'image',
          title: this.$t('onboarding.publication.portrait'),
          src: this.ambassadorImages.image || this.data.ambassador.image?.url,
          minHeight: 1200,
          minWidth: 1200,
          ratio: 1
        },
        {
          name: 'region',
          title: this.$t('onboarding.publication.regionImage'),
          src: this.images.region || this.data.images.region.url,
          minHeight: 800,
          minWidth: 1200,
          ratio: 1.5,
          disabled: this.isOnboarding
        },
        {
          name: 'lpValuationHero',
          title: this.$t('onboarding.publication.valuationHeroImage'),
          src: this.images.lpValuationHero || this.valuationHeroImage,
          minHeight: 650,
          minWidth: 1950,
          ratio: 3,
          disabled: !this.hasLpValuationHero || this.isOnboarding,
          askForImageRights: true,
          canDeleteImage: true
        }
      ].filter(({ disabled }) => !disabled)
    }
  },
  created () {
    this.rules = { email, optionalForOnboarding, optionalBooleanForOnboarding }
  },
  methods: {
    saveImage (image, type) {
      if (type === 'image') {
        this.saveImageAmbassador(image, type)
      } else {
        this.$set(this.images, type, URL.createObjectURL(image))
        this.saveCompanyImage(image, type)
      }
    },
    deleteImage (type) {
      this.$set(this.images, type, null)
      this.deleteCompanyImage(type)
    },
    saveImageAmbassador (image, type) {
      this.$set(this.ambassadorImages, type, URL.createObjectURL(image))
      this.saveAmbassadorImage(image, type)
    }
  }
}
</script>
