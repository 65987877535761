<template>
  <div>
    <div>
      <h1 class="my-4 mt-16 text-center display-1 black--text font-weight-black">
        {{ $t('settings.downgrade.header', { newPackage: productType }) }}
      </h1>
    </div>

    <div class="px-4 text-center">
      <p class="mt-2 mb-8 text-subtitle-2">
        {{ $t('settings.downgrade.text', { date: formatDate(downgradeAt,'DATE_LONG') }) }}<br>
      </p>
      <p>
        <strong>{{ $t('settings.downgrade.limits.0') }}</strong> {{ $t('settings.downgrade.limits.1') }}
      </p>
      <ul class="centered-list">
        <li
          v-for="feature in [0, 1, 2, 3] "
          :key="feature"
        >
          {{ $t(`settings.downgrade.features.${feature}`) }}
        </li>
      </ul>
    </div>

    <div class="w-full px-4 mt-6 mb-12 justify-center d-flex gap-1 flex-column flex-md-row">
      <v-btn
        elevation="20"
        :color="$vuetify.theme.themes.light.markero.blue"
        class="white--text w-xs-full px-md-16 py-md-6"
        :disabled="isLoading"
        @click="$emit('back')"
      >
        {{ $t('labels.back') }}
      </v-btn>
      <v-btn
        outlined
        :color="$vuetify.theme.themes.light.markero.blue"
        class="mx-sm-8 py-6 back-btn"
        :loading="isLoading"
        @click="submit"
      >
        {{ $t('buttons.change-package') }}
      </v-btn>
    </div>
  </div>
</template>

<script>
import * as Sentry from '@sentry/vue'
import SELF_SERVICE_PORTAL_URL from '@/modules/settings/accountSettings/queries/SelfServicePortalUrl.gql'
import CHANGE_PLAN from '@/modules/payment/queries/changePlan.gql'
import feature from '@/mixins/feature'
import date from '@/lib/date'
import { billwerkApi } from '@/modules/billwerk/lib'
import { showSnackbarMessage } from '@/lib/snackbarMessages'

export default {
  mixins: [feature, date],
  props: {
    productType: {
      type: String,
      required: true
    },
    isYearly: {
      type: Boolean,
      default: true
    },
    currency: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      isLoading: false,
      downgradeAt: ''
    }
  },
  watch: {
    async billingSelfServicePortalDetails ({ Token: token } = {}) {
      if (token) {
        const contract = await billwerkApi.getContractDetails(token)
        this.downgradeAt = contract.EndDate || contract.EndDateIfCancelledNow
      }
    }
  },
  methods: {
    async submit () {
      this.isLoading = true
      try {
        const { priceLists, packages } = this.$features.feature(this.featureNames.BILLWERK)?.config
        const productPackage = packages.find(({ productType, paymentCycle }) =>
          this.productType === productType && (!paymentCycle || paymentCycle === (this.isYearly ? 'yearly' : 'monthly'))
        )

        const planVariantId = productPackage.billwerkId
        const priceListId = Object.values(priceLists).find(({ currency }) => currency === this.currency)?.id ?? priceLists.default.id

        await this.$apollo.mutate({
          mutation: CHANGE_PLAN,
          variables: {
            input: {
              priceListId,
              planVariantId
            }
          }
        })
        this.$emit('success')
      } catch (error) {
        showSnackbarMessage('error', this.$t('alerts.settings.plan-change.error'))
        Sentry.captureException(error)
      } finally {
        this.isLoading = false
      }
    }
  },
  apollo: {
    billingSelfServicePortalDetails: { query: SELF_SERVICE_PORTAL_URL }
  }
}
</script>

<style scoped>
.centered-list {
  list-style-type: disc; /* Use disc for bullet points */
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.centered-list li {
  text-align: center;
}
</style>
